import "./about.css";
import about from "../assets/images/about.png";
import aboutTop from "../assets/images/aboutTop.png";
import aboutMiddle from "../assets/images/aboutMiddle.png";
import aboutBottom from "../assets/images/aboutBottom.png";

function About() {
  return (
    <div className="about_container">
      <div className="about_hero">
        <img src={about} alt="" />
        <h1> About us</h1>
      </div>

      <div className="about_text">
        <p>
        Walless Nation is an Evangelistic Ministry focused on fully Perpetuating the Great Commission of the Master and Lord, Jesus Christ. The Commission, as clearly stated in Matthew 28:18-20 highlights its core elements and primary assignment as:<br/>
        <br/>

        a. Soul Winning<br/>
        <br/>

        b. Making Disciples of the Nations <br/>
        <br/>

        c. Baptizing them in the name of the Father, and of the Son and of the Holy Spirit<br/>
        <br/>

        d. Teaching them to obey everything that our Lord Jesus commanded us to. <br/>
        <br/>

        The ministry particularly, derives its central mission from the text Zechariah 2:1-5 which says: “…Run, tell that young man, ‘Jerusalem will be a city without walls because of the great number of people and animals in it…” <br/>
        <br/>

        Hence, the ministry aims at fulfilling its quota towards the expansion and establishment of the great city of the heavenly Jerusalem - the Kingdom of the Lord; a city without walls. <br/>
        <br/>

        Furthermore, the ministry has others arms, through which it seeks to achieve its mandate. The arms under the body of the Walless Nation include: <br/>
        <br/>

        1. The Evangelistic / Outreach Ministries<br/>
        <br/>

        2. The Youth Ministries / Outreaches<br/>
        <br/>

        3.  Believer’s Online Prayer Network (Intercessory prayer Unit)<br/>
        <br/>

        4. The Children / Teen’s Ministry /Outreaches<br/>
        <br/>

        5. The Prison’s Ministry / Outreaches <br/>
        <br/>
        6. The Geriatric Ministry / Outreaches<br/>
        <br/>
        </p>
      </div>
      <div className="about_us">
        <div className="about_us_top">
          <img src={aboutTop} alt="" />
          <div className="about_us_text_group">
            <h2>OUR MOTTO</h2>
            <p>Making Jesus known to the ends of the earth</p>
          </div>
        </div>
        <div className="about_us_middle">
          <div className="about_us_middle_text_group" id="vision">
            <h2>OUR VISION</h2>
            <p>
            To win souls for Christ through the preaching of the gospel of God's kingdom to the nations of the world (Matt 24:14) and to reconcile all creation to God (Jesus) the soon coming King of Glory (2 Corinthians 5:17-18).<br/>
            <br/>
To raise kingdom servants through discipleship using the basis of the foundation of the apostles and prophets with Jesus as the chief cornerstone (Ephesian 2:20).<br/>
            <br/>
            </p>
          </div>
          <img src={aboutMiddle} alt="" />
        </div>
        <div className="about_us_top">
          <img src={aboutBottom} alt="" />
          <div className="about_us_text_group" id="mission">
            <h2>OUR MISION</h2>
            <p>
            The mission is aimed at proclaiming the Gospel of Christ, Soul winning, confirming men as disciples and equipping them for the work of ministry. The Comission, as clearly stated in Matthew 28:16-20, Mark 16: 14-16, Luke 24: 44-49, John 20:21-23 highlights its core elements as:<br/>
            <br/>

Soul Winning<br/>
            <br/>

Making Disciples of the Nations.<br/>
            <br/>

Baptizing them in the name of the Father, and of the Son and of the Holy Spirit<br/>
            <br/>

Teaching them to obey everything that Jesus commanded his disciples to do.<br/>
            <br/>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default About;
