import axios from "axios";

// export const endpoint = "http://localhost:1337";
export const endpoint = "https://walless-admin.herokuapp.com";

export const apiFunctions = {
  getHomily: async () => get("/homilies"),
  getEvents: async () => get("/events"),
  getTestimonies: async () => get("/testimonies"),
  getGalleries: async () => get("/galleries"),
  postTestimony: async fd => post("/testimonies", fd)
};

export const get = path => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${endpoint}${path}`)
      .then(result => {
        resolve(result.data);
      })
      .catch(error => {
        console.log(error.response);
        reject({ status: 500, msg: error.response });
      });
    setTimeout(() => reject({ status: 500, msg: "connection error" }), 10000);
  });
};
export const post = (path, fd) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${endpoint}${path}`, fd, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(result => {
        resolve(result.data);
      })
      .catch(error => {
        console.log(error.response);
        reject({ status: 500, msg: error.response });
      });
    setTimeout(() => reject({ status: 500, msg: "connection error" }), 10000);
  });
};

export const getWithToken = (path, token) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${endpoint}${path}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(result => {
        resolve(result.data);
      })
      .catch(error => {
        console.log(error.response);
        reject({ status: 500, msg: error.response });
      });
    setTimeout(() => reject({ status: 500, msg: "connection error" }), 10000);
  });
};
