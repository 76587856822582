import "./giving.css";
import giving from "../assets/images/giving.png";
import usFlag from '../assets/images/usFlag.png'
import nigeriaFlag from '../assets/images/nigeriaFlag.png'
const accounts = [
  {
    type: 'Naira Account',
    image: nigeriaFlag,
    bankName: 'Access Bank Account',
    accountName: 'Walless Church',
    accountNumber: '1615820634'
  },
  {
    type: 'Naira Account',
    image: nigeriaFlag,
    bankName: 'Zenith Bank',
    accountName: 'Walless Church',
    accountNumber: '1222991899'
  },
  {
    type: 'Dollar Account',
    image: usFlag,
    bankName: 'Access Bank Account',
    accountName: 'Walless Church',
    accountNumber: '1619995512'
  },
]


function Giving() {
  return (
    <div className="giving_container">
      <div className="giving_top" />

      <div className="giving_group">
        <div className="giving">
          <img src={giving} alt="" />
        </div>

        <div className="giving_form_container">
          <h2>GIVING JUST GOT EASIER</h2>
          <form className="giving_form">
            <div className="giving_form_top">
              <input name="first_name" type="text" placeholder="First name" />
              <input name="last_name" type="text" placeholder="Last name" />
            </div>
            <input name="email" type="email" placeholder="Email" />
            <input name="amount" type="text" placeholder="Amount sent" />
            <button>Send</button>
            <div className="giving_form_numbers">
              <p className='giving_details_title'>Kindly pay into any of the account numbers below </p>

            <div className="accounts">
              {
                accounts?.map((account)=> (
                  <div className="account_item">
                <div className='account_type'>
                <img src={account?.image} alt="" />
                 <p><b>{account?.type}</b></p> 
                </div>
                <div className='account-details'>
                  <p><b>Bank Name:</b> {account?.bankName}</p>
                  <p><b>Account Name:</b> {account?.accountName}</p>
                  <p><b>Account Number:</b> {account?.accountNumber}</p>
                </div>
              </div>
                ))
              }
            </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Giving;
