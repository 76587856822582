import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import "./App.css";
import logo from "./assets/icons/walless.png";
import Home from "./screens/home";
import About from "./screens/about";
import Media from "./screens/media";
import Impact from "./screens/impact";
import Giving from "./screens/giving";
import MediaGallery from "./screens/media_gallery";
import { SiFacebook, SiInstagram, SiYoutube } from "react-icons/si";
import { RiMenu2Line } from "react-icons/ri";

function App() {
  const nav = () => {
    console.log("nav pressed");
    if (document.querySelector(".sidebar").style.display === "flex") {
      document.querySelector(".sidebar").style.display = "none";
    } else {
      document.querySelector(".sidebar").style.display = "flex";
    }
  };
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Link to="/" className="logo-container">
            <img src={logo} alt="" />
          </Link>

          <ul className="header_links">
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link to="/about">ABOUT</Link>
            </li>
            <li>
              {" "}
              <Link to="/media">MEDIA</Link>
            </li>
            <li>
              <Link to="/impact">IMPACT</Link>
            </li>
            <li>
              <Link to="/giving">GIVING</Link>
            </li>
            <li>
            <a href="https://paystack.shop/wallessnationstore" target='_blank' rel='noreferrer'>
              SHOP
              </a>
              </li>
          </ul>

          <RiMenu2Line className="header_navIcon" onClick={nav} />
        </header>

        <aside className="sidebar">
          <ul className="sidebar_links">
            <li onClick={nav}>
              <Link to="/">HOME</Link>
            </li>
            <li onClick={nav}>
              <Link to="/about">ABOUT</Link>
            </li>
            <li onClick={nav}>
              <Link to="/media">MEDIA</Link>
            </li>
            <li onClick={nav}>
              <Link to="/impact">IMPACT</Link>
            </li>
            <li onClick={nav}>
              <Link to="/giving">GIVING</Link>
            </li>
            <li onClick={()=> alert('Coming soon')}>SHOP</li>
          </ul>
        </aside>
        <Route path="/" component={Home} exact={true} />
        <Route path="/about" component={About} />
        <Route path="/media" component={Media} />
        <Route path="/impact" component={Impact} />
        <Route path="/giving" component={Giving} />
        <Route path="/media_gallery" component={MediaGallery} />

        <footer className="footer">
          <div className="footer_group1">
            <img src={logo} alt="" />
            <p>WALLESS CHURCH</p>
            <div className="footer_icons">
              <a href="https://www.facebook.com/walless.church" target='_blank' rel='noreferrer'>
              <SiFacebook className="footer_icon" />
                </a>
              <a href="https://www.instagram.com/wallesschurch/" target='_blank' rel='noreferrer'>
              <SiInstagram className="footer_icon" />
                </a>
              {/* <a href="https://www.facebook.com/walless.church" target='_blank' rel='noreferrer'>
              <SiTwitter className="footer_icon" />
                </a> */}
              <a href="https://www.facebook.com/walless.church" target='_blank' rel='noreferrer'>
              <SiYoutube className="footer_icon" />
                </a>
              {/* <SiInstagram className="footer_icon" />
              <SiTwitter className="footer_icon" />
              <SiYoutube className="footer_icon" /> */}
            </div>
          </div>

          <div className="footer_group">
            <h3>
            <a href="/#join">JOIN US</a></h3>
            <ul>
              <li>
              <a href="/#home">HOME</a>
              </li>
              <li>
              <a href="https://paystack.shop/wallessnationstore" target='_blank' rel='noreferrer'>
                SHOP
                </a>
                </li>
              <li>
                <Link to="/giving">GIVING</Link>
              </li>
            </ul>
          </div>

          <div className="footer_group">
            <h3>
            <Link to="/about">ABOUT</Link></h3>
            <ul>
              <li>
                <Link to="/about">ABOUT US</Link>
              </li>
              <li>
             <a href="/about#vision">
             OUR VISION
              </a> 
                </li>
              <li>
              <a href="/about#mission">
                OUR BELIEF
                </a>
                </li>
              {/* <li>PRIVACY POLICY</li> */}
            </ul>
          </div>

          <div className="footer_group">
            <h3>
              <Link to="/media">MEDIA</Link>
            </h3>
            <ul>
              <li onClick={()=> alert('Coming soon')}>E-LIBRARY</li>
              <li><Link to='/media_gallery'>GALLERY</Link></li>
              <li onClick={()=> alert('Coming soon')}>VIDEO MESSAGE</li>
              <li onClick={()=> alert('Coming soon')}>AUDIO MESSAGE</li>
            </ul>
          </div>

          <div className="footer_group">
            <h3>
              <Link to="/impact">IMPACT</Link>
            </h3>
            <ul>
              <li> <a href="/impact#read_test">READ TESTIMONIES</a></li>
              <li> <a href="/impact#watch_test">WATCH TESTIMONIES</a></li>
              <li><a href="/impact#share_test">SHARE TESTIMONIES</a></li>
            </ul>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
