import React, { useState, useEffect } from "react";
// import { apiFunctions } from "../helpers/api";
import "./impact.css";
import test1 from "../assets/images/test1.png";
import test2 from "../assets/images/test2.png";
import test3 from "../assets/images/test3.png";

import watchTest from "../assets/images/watchTest.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import testimonies from '../Testimonials.json'

const testImages = [
  {
    id: 1,
    image: test1
  },
  {
    id: 2,
    image: test2
  },
  {
    id: 3,
    image: test3
  }
];

function Impact() {
  const [testimony, setTestimony] = useState("");
  const [testimonyModal, setTestimonyModal] = useState(false);
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");

  var settings = {
    className: "slider",
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  useEffect(() => {
    fetchTestimonies();
  }, []);

  const handleTestimonyClick = () => {
    setTestimonyModal(!testimonyModal);
  };

  const fetchTestimonies = async () => {
    // try {
    //   let testimonies = await apiFunctions.getTestimonies();
    //   if (testimonies) {
    //     console.log({ testimonies });
    //     setTestimonies(testimonies);
    //   }
    // } catch (err) {
    //   console.log({ err });
    // }
  };

  // const handleSubmit = async e => {
  //   e.preventDefault();
  //   if (name === "" || city === "" || country === "" || testimony === "") {
  //     alert("All fields are required");
  //     return;
  //   }

  //   let payload = {
  //     name: name,
  //     location: `${city}, ${country}`,
  //     testimony
  //   };

  //   console.log({ payload });

  //   try {
  //     let sendTest = await apiFunctions.postTestimony(payload);
  //     if (sendTest) {
  //       console.log(sendTest);
  //       setName("");
  //       setCity("");
  //       setCountry("");
  //       setTestimony("");
  //       fetchTestimonies();
  //       handleTestimonyClick();
  //     }
  //   } catch (err) {
  //     console.log({ err });
  //   }
  // };

  const handleOpenEmail = (e) => {
    e.preventDefault();
    if (name === "" || city === "" || country === "" || testimony === "") {
      alert("All fields are required");
      return;
    }
    const email = 'wallesstestimonies@gmail.com';
    const subject = 'Testimony';

    const body = `Hi Sir/Madam,\n\nName: ${name}\n\nLocation: ${city}, ${country}\n\n${testimony}`;

    // Create the mailto link
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    // Open the default email client
    window.location.href = mailtoLink;

        setName("");
        setCity("");
        setCountry("");
        setTestimony("");
   
  };


  return (
    <div className="impact_container">
      <div className="impact_top" />

      {testimonyModal && (
        <div className="testimony_modal">
          <div className="testimony_button" onClick={handleTestimonyClick}>
            X
          </div>

          {testimonies.map(el => {
            return (
              <div className="testimony_group" key={el.id}>
                <h3>{el.name}</h3>
                <p>{el.testimony}</p>
                <p>{el.location}</p>
              </div>
            );
          })}
        </div>
      )}

      <div className="impact_read_test" id="read_test">
        <div className="impact_read_texts">
          <h2 onClick={handleTestimonyClick}> Read Testimonies</h2>
          <p>read amazing testimonies of God’s wonders </p>
        </div>

        <Slider {...settings}>
          {testImages.map(el => (
            <div key={el.id}>
              <img src={el.image} alt="" className="impact_images" />
            </div>
          ))}
        </Slider>
      </div>

      <div className="impact_watch_test" id='watch_test'>
        <div className="impact_watch_left">
          <img src={watchTest} alt="" />
        </div>
        <div className="impact_watch_right">
          <h2>Watch Testimonies</h2>
          <p>Click here to watch amazing testimonies shared by people</p>
          <button>watch now</button>
        </div>
      </div>

      <div className="impact_share" id="share_test">
        <div className="impact_share_left">
          <h2>Share Testimonies</h2>
          <p>
            Please share your testimonies with us as we join you to thank God
            for His Love towards you.
          </p>
        </div>

        <div className="impact_share_right">
          <form className="impact_share_form" onSubmit={handleOpenEmail}>
            <input
              name="name"
              type="text"
              placeholder="name"
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <div className="impact_form_group">
              <input
                name="city"
                type="text"
                placeholder="city"
                value={city}
                onChange={e => setCity(e.target.value)}
              />
              <input
                name="country"
                type="text"
                placeholder="country"
                value={country}
                onChange={e => setCountry(e.target.value)}
              />
            </div>
            <textarea
              value={testimony}
              onChange={e => setTestimony(e.target.value)}
              placeholder="Your testimony"
            />
            <button>Send</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Impact;
