import React, { useEffect, useState } from "react";
import "./media_gallery.css";
import { SiFacebook, SiInstagram, SiYoutube, SiTwitter } from "react-icons/si";
import Slider from "react-slick";
import axios from "axios";

function MediaGallery() {
  const [images, setImages] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      axios
        .get(
          `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/list/media.json`
        )
        .then((res) => {
          const images = res?.data?.resources?.map((item) => ({
            url: `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/v1675065099/${item?.public_id}.${item.format}`,
          }));
          setImages(images);
        });
    } catch (err) {
      console.log({ err });
    }
  };

  const settings = {
    className: "portfolio_sliderStyle",
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    initialSlide: initialSlide,
  };

  const handleClick = (e) => {
    setInitialSlide(e);
    setOpenModal(!openModal);
  };

  return (
    <div className="media_gall_container">
      {openModal && (
        <div className="portfolio_modal">
          <div
            className="portfolio_modal_close"
            onClick={() => setOpenModal(!openModal)}
          >
            X
          </div>
          <div className="portfolio_modal_img_container">
            <Slider {...settings}>
              {images.map((el, index) => {
                return (
                  <div key={index}>
                    <img src={el.url} alt="" className="portfolio_modal_img" />
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      )}
      <div className="media_gall_group">
        <div>
          {!!images.length > 0 &&
            images.map((el, id) => {
              return (
                <img
                  src={el.url}
                  alt=""
                  key={el?.url}
                  onClick={(e) => handleClick(id)}
                />
              );
            })}
        </div>
      </div>

      <div className="media_gall_pages">
        <div>1</div>
      </div>

      <div className="media_gall_icons">
        <SiFacebook className="media_gall_icon" />
        <SiInstagram className="media_gall_icon" />
        <SiTwitter className="media_gall_icon" />
        <SiYoutube className="media_gall_icon" />
      </div>
    </div>
  );
}

export default MediaGallery;
