import React from "react";
import "./media.css";
import logo from "../assets/icons/walless.png";
import { SiFacebook, SiInstagram, SiYoutube, SiTwitter } from "react-icons/si";
import mediaGall from "../assets/images/mediaGall.png";
import wallessaudio from "../assets/images/wallessaudio.png";
import wallessebook from "../assets/images/wallessebook.png";
import wallessradio from "../assets/images/wallessradio.png";
import wallessvideo from "../assets/images/wallessvideo.png";
import ebooks from "../assets/icons/ebooks.png";
import gallery from "../assets/icons/gallery.png";
import radio from "../assets/icons/radio.png";
import video from "../assets/icons/video.png";
import audio from "../assets/icons/audio.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const media = [
  {
    id: "1",
    title: "EBOOKS",
    icon: ebooks,
    image: wallessebook,
    nav: "media"
  },
  {
    id: "2",
    title: "GALLERY",
    icon: gallery,
    image: mediaGall,
    nav: "media_gallery"
  },
  {
    id: "3",
    title: "AUDIO MESSAGE",
    icon: audio,
    image: wallessaudio,
    nav: "media"
  },
  {
    id: "4",
    title: "VIDEO MESSAGE",
    icon: video,
    image: wallessvideo,
    nav: "media"
  },
  {
    id: "5",
    title: "WALLESS RADIO",
    icon: radio,
    image: wallessradio,
    nav: "media"
  }
];

function Media() {
  const handleClick = el => {
    console.log({ el }, el.id);
    if (el.id === "2") {
      return
    } else {
      alert('coming soon')
    }
  };

  return (
    <div className="media_container">
      <div className="media_hero">
        <img src={logo} alt="" />

        <div className="media_icons">
          <SiFacebook className="media_icon" />
          <SiInstagram className="media_icon" />
          <SiTwitter className="media_icon" />
          <SiYoutube className="media_icon" />
        </div>
      </div>

      <div className="media_section">
          {media.map((el, index) => (
            <div
              key={el.id}
              className="media_card"
              onClick={() => handleClick(el)}
            >
              <Link to={`/${el?.nav}`}>
              <img src={el.image} alt="" className="media_images" />
              <div className="media_icon_cont">
                <img src={el.icon} alt="" className="media_group_icon" />
              </div>
              <h4>{el.title}</h4>
              </Link>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Media;
