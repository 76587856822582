import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "./home.css";
import logo from "../assets/icons/walless.png";
import givebg from "../assets/images/givebg.jpg";
import subhero from "../assets/images/subhero.jpg";
import { BsArrowRight } from "react-icons/bs";
import hero_video from "./hero_video.mp4";
import Mailchimp from "react-mailchimp-form";
import { apiFunctions } from "../helpers/api";
import pastor1 from '../assets/images/pastor1.png'
import pastor2 from '../assets/images/pastor2.png'
import { useSpring, animated } from "react-spring";
import { useIntersectionObserver } from "../hooks/observer";

function Home() {
  const triggerRef = useRef();
  const dataRef = useIntersectionObserver(triggerRef, {
    freezeOnceVisible: true
  });

  const pastorStyle =  useSpring({
    config: { mass: 10, tension: 400, friction: 50 },
    from: { opacity: 0, marginRight: -500, transform: "rotateZ(0deg)" },
    to: {
      opacity: dataRef?.isIntersecting ? 1 : 0,
      marginRight: dataRef?.isIntersecting ? 0 : -500,
      transform: dataRef ? "rotateZ(360deg)" : "rotateZ(0deg)"
    },
  });
  
  const [playVideo, setPlayVideo] = useState(true);
  const [homily, setHomily] = useState({});
  const [events, setEvents] = useState([]);
  const videoRef = useRef(null);


  useEffect(() => {
    getHomily();
    getEvents();
  }, []);

  const getHomily = async () => {
    try {
      let homily = await apiFunctions.getHomily();
      if (homily) {
        setHomily(homily?.[0]);
      }
      console.log({ homily });
    } catch (err) {
      console.log({ err });
    }
  };

  const getEvents = async () => {
    try {
      let events = await apiFunctions.getEvents();
      if (events) {
        setEvents(events);
      }
    } catch (err) {
      console.log({ err });
    }
  };

  const handlePress = () => {
    if (playVideo) {
      videoRef.current.pause();
      setPlayVideo(false);
      return;
    }

    videoRef.current.play();
    setPlayVideo(true);
  };

  console.log({events})

  return (
    <div className="home_container" id='home'>
      <div className="home_hero">
        <video
          width="100%"
          height="100%"
          autoPlay
          loop
          muted
          ref={videoRef}
          onClick={handlePress}
        >
          <source src={hero_video} type="video/mp4" />
        </video>
      </div>
      <div className="homily">
        <div className="homily_left">
          <img src={logo} alt="" />
          <h3>WALLESS CHURCH</h3>
        </div>
        <div className="homily_right">
          <h2>Homily</h2>
          <p>
            {!!homily?.verse
              ? homily?.verse
              : `"Be tolerant with each other and, if someone has a complaint against
            anyone, forgive each other. As the Lord forgave you, so also forgive
            each other." `}
          </p>
          <div className="homily_hr" />
          <p>{homily?.book ? homily?.book : "Colossians 3:13"}</p>
        </div>
      </div>
      <div className="home_pastors">
      <div>
        <h1>OUR PIONEERS</h1>
        <p>
          Pst. Ferdinand and Ugochi are on a mission together to:
          Go therefore, and teach all nations, baptizing them in the name of the Father, and of the Son, and of the Holy Ghost: Teaching them to observe all things whatsoever have been commanded us by Christ Jesus.<br/>
                  <br/>

          Their mandate is to take the Gospel of the Kingdom (JESUS CHRIST) to the ends of the earth.<br/>
        <br/>

          In addition to missions, Pst. Ferdinand is a graduate of Biochemistry and a Masters Degree holder with other certifications in other relevant disciplines. Pst. Ferdinand is a trained Pastor from the Assemblies of God Divinity School Nigeria with a Diploma in Theology and a Degree in Biblical Studies. His passion and desire is to see souls saved, lives transformed and Jesus glorified.<br/>
        <br/>

          Ugochi is a graduate of Computer Science with a Postgraduate Diploma in Education and a Masters Degree in Education Guidance and Counselling from the University of Lagos Nigeria. Ugochi helps men, women, youths, teenagers and children in finding their true identity and purpose in Christ Jesus and their various spheres of influence through teachings, prayers, counselling, song ministrations, and other platforms.<br/>
        <br/>

          Pst. Ferdinand and Ugochi enjoys teaching, reading, travelling, counselling, singing, music and helping men come to the Knowledge of God and of his Son Jesus Christ.<br/>
        <br/>
        </p>
        </div>
        
        <div style={pastorStyle} className="pastors">
        <animated.img src={pastor1} alt="" className="home-pastors-img1" style={pastorStyle}  />
        <div ref={triggerRef} />
        <animated.img src={pastor2} alt="" className="home-pastors-img2" style={pastorStyle}  />
        </div>
       
      </div>

      {/* <div className="events">
        <div className="events_section">
          <h1>UPCOMING EVENTS</h1>
          <div className="events_hr" />

          <div className="events_group">
            {events.map((el, i) => {
              let image = el?.thumbnail?.url;
              return (
                <div className="events_card" key={el?.id}>
                  <h3>{el?.title}</h3>
                  <img src={image} alt="" />
                  <p>{i === 1 ? 'Every Fridays' : 'Upcoming' }</p>
                  <p>
                    {i === 1 && '11 PM'}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <p className="event_text">RUN TO GOD NOT FROM HIM</p>
      </div> */}

      <div className="home_give">
        <img src={givebg} alt="" className="home_give_bg" />
        <img src={logo} alt="" className="home_give_logo" />
        <div className="give_group">
          <div className="give_group_title">
            <h2 className="give_group_title1">WAYS TO GIVE</h2>
            <h2 className="give_group_title2">WAYS TO GIVE</h2>
            <h2 className="give_group_title3">WAYS TO GIVE</h2>
          </div>

          <div className="give_group_body">
            <p>"When we give, we make room for more."</p>
            <div className="give_action">
              <Link to="/giving">
                <h2>GIVE NOW</h2>
              </Link>
              <BsArrowRight className="give_icon" />
            </div>
          </div>
        </div>
      </div>

      <div className="home_sub" id='join'>
        <img src={subhero} alt="" className="home_sub_hero" />
        <div className="home_sub_section">
          <p className="home_sub_title">Jesus Feast</p>
          <img src={logo} alt="" />

          <div className="sub_group">
            <h2>STAY TUNED</h2>
            <p>
              Subscribe to our newsletter, never miss any
              <br /> event, service, outreach etc.
            </p>
            <br />
            <p>We love you, stay lifted.</p>

            <div className="mail_form_container">
              <Mailchimp
                action={process.env.REACT_APP_MAILCHIMP_URL}
                //Adding multiple fields:
                fields={[
                  {
                    name: "EMAIL",
                    placeholder: "Email",
                    type: "email",
                    required: true
                  }
                ]}
                // Change predetermined language
                messages={{
                  sending: "Sending...",
                  success: "Thank you for subscribing!",
                  error: "An unexpected internal error has occurred.",
                  empty: "You must write an e-mail.",
                  duplicate:
                    "Too many subscribe attempts for this email address",
                  button: "Subscribe!"
                }}
                // Add a personalized class
                className="chimp_form"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
